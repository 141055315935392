import { useFormik } from "formik";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Button, Link, TextField } from "@mui/material";
import styles from "./ResetPassword.module.scss";
import { toast } from "react-toastify";
import { oneTimeLogin } from "shared/Services/Auth.service";
import axios from "axios";
import PasswordPolicies from "components/Shared/PasswordPolicies/PasswordPolicies";

function ResetPassword() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const validationSchema = Yup.object().shape({
    password: Yup
      .string()
      .required("New password is required"),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    } as {
      password: string;
      confirmPassword: string;
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      oneTimeLogin({
        new_pass: values.password,
        ...Object.fromEntries(searchParams) as {
          uid: string;
          timestamp: string;
          hash: string;
        }
      }).then(resp => {
        if (axios.isAxiosError(resp)) {
          toast.error(resp?.message || "Request failed.");
        } else {
          if (resp.data.error) {
            const messages = resp.data.error.split("<br/>");
            const markup = messages.length > 1 ? <ul className={styles["validation-error"]}>
              {messages.map((item: any, i: number) => <li key={i}>{item}</li>)}
            </ul> : messages[0];

            toast.error(markup || "Password validation error.");
          } else {
            toast.success("Password changed!");
            navigate('/')
          }
        }
      });
    },
  });

  return (
    <div className={styles["up-container"]}>
      <h2 className={styles["up-title"]}>Reset Password</h2>

      <form onSubmit={formik.handleSubmit} className={styles["up-form"]}>
        <TextField
          classes={{ root: styles["up-form-password"] }}
          placeholder="New password"
          variant="filled"
          hiddenLabel={true}
          fullWidth={true}
          InputProps={{
            classes: {
              root: styles["up-form-password-input"],
              focused: styles["focused"],
            },
          }}
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          inputProps={{
            type: "password",
          }}
        />
        <TextField
          classes={{ root: styles["up-form-confirm-password"] }}
          placeholder="Confirm new password"
          variant="filled"
          hiddenLabel={true}
          fullWidth={true}
          InputProps={{
            classes: {
              root: styles["up-form-confirm-password-input"],
              focused: styles["focused"],
            },
          }}
          id="confirmPassword"
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          inputProps={{
            type: "password",
          }}
        />

        <PasswordPolicies />

        <Button
          type="submit"
          color={"inherit"}
          classes={{ root: styles["up-form-button"] }}
          variant="contained"
        >
          RESET
        </Button>
      </form>
      <Link
        underline="none"
        onClick={() => navigate(`/`)}
        classes={{ root: styles["up-form-link"] }}
      >
        Back to Login screen
      </Link>
    </div>
  );
}

export default ResetPassword;
