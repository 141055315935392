const moment = require("moment-timezone");
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CountriesStatesContext,
  RouterContext,
  UserDataContext,
} from "shared/Contexts";
import { formatAddressToString } from "shared/helpers/common.helper";
import { WeekDays, WorkingHoursFormat } from "shared/helpers/profile.helper";
import { formatMomentToAMPM } from "shared/helpers/time.helper";

import { ProfileI } from "@interfaces";
import AddIcon from "@mui/icons-material/Add";
import { Button, IconButton } from "@mui/material";

import DeleteIcon from "../../../assets/icons/delete.svg";
import ProfileIcon from "../../../assets/icons/profile.svg";
import TelegramIcon from "../../../assets/icons/socials/telegram.svg";
import WhatsAppIcon from "../../../assets/icons/socials/whatsapp.svg";
import UpAvatar from "./Avatar/Avatar";
import styles from "./Profile.module.scss";
import { GoogleMap, Marker } from "@react-google-maps/api";

function Profile({
  profileInfo,
  deleteSpecialDay,
  deleteTimeReservation,
}: {
  profileInfo: ProfileI;
  deleteSpecialDay?: (index: number) => void;
  deleteTimeReservation?: (index: number) => void;
}) {
  const navigate = useNavigate();
  const { countriesStates } = useContext(CountriesStatesContext);
  const { currentUser } = useContext(UserDataContext);
  const [routerValue] = useContext(RouterContext);

  const renderIcon = (param: string) => {
    if (param.includes("https://wa.me/"))
      return <img alt="WhatsApp" src={WhatsAppIcon} />;
    if (param.includes("https://t.me/"))
      return <img alt="Telegram" src={TelegramIcon} />;
    if (param.includes("https://m.me/"))
      return <img alt="Telegram" src={TelegramIcon} />;
  };

  return (
    <div className={styles["up-container"]}>
      <div className={styles["up-card"]}>
        <div className={styles["up-card-edit"]}>
          <Button
            classes={{ root: styles["up-card-edit-button"] }}
            color={"inherit"}
            onClick={() => navigate("edit")}
          >
            Edit
          </Button>
        </div>
        <div className={styles["up-card-avatar"]}>
          <UpAvatar
            name={profileInfo.name}
            avatar={profileInfo?.avatar?.url || null}
            defaultImage={ProfileIcon}
          />
        </div>
        <div className={styles["up-card-name"]}>{profileInfo.name}</div>
        {currentUser?.roles.length ? (
          <div className={styles["up-card-position"]}>
            {currentUser?.roles.join(", ")}
          </div>
        ) : null}
      </div>

      <div className={styles["up-content"]}>
        {profileInfo.phoneNumber?.length ? (
          <>
            <label className={styles["up-content-label"]}>Phone number</label>
            <div className={styles["up-content-value"]}>
              {profileInfo.phoneNumber.map((p, index) => (
                <a
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={`tel:${p}`}
                >
                  {p}
                </a>
              ))}
            </div>
          </>
        ) : null}

        {profileInfo.email?.length ? (
          <>
            <label className={styles["up-content-label"]}>Email</label>
            <div className={styles["up-content-value"]}>
              {profileInfo.email.map((p, index) => (
                <a
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                  href={`mailto:${p}`}
                >
                  {p}
                </a>
              ))}
            </div>
          </>
        ) : null}
        {profileInfo.agentProfile && (
          <>
            {profileInfo.agentProfile.address.country_code && (
              <>
                <label className={styles["up-content-label"]}>Address</label>
                <div className={styles["up-content-value"]}>
                  {formatAddressToString(
                    profileInfo.agentProfile.address,
                    countriesStates
                  )}
                </div>
              </>
            )}
            {profileInfo.agentProfile.links.length ? (
              <>
                <label className={styles["up-content-label"]}>Links</label>
                <div className={styles["up-content-value"]}>
                  {profileInfo.agentProfile.links.map((l, index) => (
                    <a key={index} rel="noreferrer" target="_blank" href={l}>
                      {l}
                    </a>
                  ))}
                </div>
              </>
            ) : null}

            {profileInfo.agentProfile.messengers?.length ? (
              <>
                <label className={styles["up-content-label"]}>
                  Contact via
                </label>
                <div className={styles["up-content-icons"]}>
                  {profileInfo.agentProfile.messengers?.map((l, index) => (
                    <a key={index} rel="noreferrer" target="_blank" href={l}>
                      {renderIcon(l)}
                    </a>
                  ))}
                </div>
              </>
            ) : null}

            <div className={styles["up-content-row"]}>
              {profileInfo.agentProfile.workingHours && (
                <div>
                  <label className={styles["up-content-label"]}>
                    Working Hours in{" "}
                    <strong>{currentUser?.timezone.value || "UTC"}</strong>{" "}
                    timezone
                  </label>
                  <div
                    className={styles["up-content-value"]}
                    style={{ flexDirection: "column" }}
                  >
                    {profileInfo.agentProfile.workingHours
                      ?.filter((i) => i.checked)
                      ?.map((i, index) => (
                        <div key={index}>{WorkingHoursFormat(i)}</div>
                      ))}
                    {profileInfo.agentProfile.breaks?.checked && (
                      <div>
                        Break: {profileInfo.agentProfile.breaks.from}-
                        {profileInfo.agentProfile.breaks.to}
                      </div>
                    )}
                  </div>
                </div>
              )}
              {profileInfo.agentProfile.DayOffs?.length ? (
                <div>
                  <label className={styles["up-content-label"]}>Day Offs</label>
                  <div className={styles["up-content-value"]}>
                    {profileInfo.agentProfile.DayOffs?.map(
                      (i) => WeekDays[i]
                    ).join(", ")}
                  </div>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>

      {profileInfo.agentProfile && (
        <>
          <div className={styles["up-special"]}>
            <div className={styles["up-special-title"]}>
              Special working hours
              {profileInfo.agentProfile.specialDays?.length ? (
                <IconButton
                  color="inherit"
                  classes={{ root: styles["up-special-add"] }}
                  onClick={() => navigate("/profile/special-days/create")}
                >
                  <AddIcon color={"inherit"} fontSize={"small"} />
                </IconButton>
              ) : null}
            </div>
            {profileInfo.agentProfile.specialDays?.length ? (
              <>
                <div className={styles["up-special-grid"]}>
                  {profileInfo.agentProfile.specialDays.map((i, index) => (
                    <div className={styles["up-special-grid-item"]} key={i.id}>
                      <span className={styles["up-special-grid-title"]}>
                        {i.title}
                      </span>
                      <span className={styles["up-special-grid-date"]}>
                        {moment(i.date.from)
                          .tz(currentUser!.timezone.value)
                          .format("ll")}
                        {i.date.to &&
                          ` - ${moment(i.date.to)
                            .tz(currentUser!.timezone.value)
                            .format("ll")}`}
                      </span>
                      <span className={styles["up-special-grid-time"]}>
                        {i.workingHours?.from &&
                          `Working Hours: ${formatMomentToAMPM(
                            i.workingHours?.from,
                            currentUser!.timezone.value
                          )} - ${formatMomentToAMPM(
                            i.workingHours?.to,
                            currentUser!.timezone.value
                          )}`}
                      </span>
                      <span className={styles["up-special-grid-break"]}>
                        {i.break?.from &&
                          `Break: ${formatMomentToAMPM(
                            i.break?.from,
                            currentUser!.timezone.value
                          )} - ${formatMomentToAMPM(
                            i.break?.to,
                            currentUser!.timezone.value
                          )}`}
                      </span>
                      <span className={styles["up-special-grid-all"]}>
                        {!i.workingHours?.from && "All day"}
                      </span>
                      {deleteSpecialDay && (
                        <IconButton
                          onClick={() => deleteSpecialDay(index)}
                          className={styles["up-special-grid-delete"]}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p className={styles["up-special-grid-no-items"]}>
                <Button
                  onClick={() => navigate("/profile/special-days/create")}
                  size={"small"}
                  variant="outlined"
                  color={"inherit"}
                  classes={{ root: styles["up-special-grid-no-items-button"] }}
                  startIcon={<AddIcon color="inherit" />}
                >
                  Add Special day
                </Button>
              </p>
            )}
          </div>
          <div className={styles["up-special"]}>
            <div className={styles["up-special-title"]}>
              Time Reservation
              {profileInfo.agentProfile.timeReservation?.length ? (
                <IconButton
                  color="inherit"
                  classes={{ root: styles["up-special-add"] }}
                  onClick={() => navigate("/profile/time-reservation")}
                >
                  <AddIcon color={"inherit"} fontSize={"small"} />
                </IconButton>
              ) : null}
            </div>
            {profileInfo.agentProfile.timeReservation?.length ? (
              <>
                <div className={styles["up-special-grid"]}>
                  {profileInfo.agentProfile.timeReservation.map((i, index) => (
                    <div className={styles["up-special-grid-item"]} key={i.id}>
                      <span className={styles["up-special-grid-title"]}>
                        {i.title}
                      </span>
                      <span className={styles["up-special-grid-date"]}>
                        {moment(i.date)
                          .tz(currentUser!.timezone.value)
                          .format("ll")}
                      </span>
                      <span className={styles["up-special-grid-time"]}>
                        {i.start_time &&
                          `${formatMomentToAMPM(
                            i.start_time,
                            currentUser!.timezone.value
                          )} - ${formatMomentToAMPM(
                            i.end_time,
                            currentUser!.timezone.value
                          )}`}
                      </span>
                      {deleteTimeReservation && (
                        <IconButton
                          onClick={() => deleteTimeReservation(index)}
                          className={styles["up-special-grid-delete"]}
                        >
                          <img src={DeleteIcon} alt="Delete" />
                        </IconButton>
                      )}
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <p className={styles["up-special-grid-no-items"]}>
                <Button
                  onClick={() => navigate("/profile/time-reservation")}
                  size={"small"}
                  variant="outlined"
                  color={"inherit"}
                  classes={{ root: styles["up-special-grid-no-items-button"] }}
                  startIcon={<AddIcon color="inherit" />}
                >
                  Add Time Reservation
                </Button>
              </p>
            )}
          </div>
          <div className={styles["up-map"]}>
          <label className={styles["up-content-label"]}>My location</label>
            {routerValue.loaded && routerValue.myLocation ? (
              <GoogleMap
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  zoomControl: false,
                  streetViewControl: false,
                }}
                clickableIcons={false}
                mapContainerStyle={{
                  width: "100%",
                  height: "300px",
                }}
                center={{
                  lat: Number(routerValue.myLocation?.lat) || 0,
                  lng: Number(routerValue.myLocation?.lng) || 0,
                }}
                zoom={16}
              >
                <Marker
                  position={{
                    lat: Number(routerValue.myLocation.lat),
                    lng: Number(routerValue.myLocation.lng),
                  }}
                />
              </GoogleMap>
            ) : (
              <div> Please enable location services to view the map </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Profile;
