import Header from "components/Shared/Header/Header";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ManagerContext,
  RouterContext,
  UserDataContext,
} from "shared/Contexts";
import LoadingSpinner from "components/Shared/LoadingSpinner/LoadingSpinner";
import { Button } from "@mui/material";
import DayScheduleCalendar from "../DayScheduleCalendar/DayScheduleCalendar";
import styles from "./DayScheduleView.module.scss";
import { Roles } from "../../../../shared/Enums";
import AgentProfileNotExist from "components/Shared/AgentProfileNotExist/AgentProfileNotExist";

function DayScheduleView() {
  const navigate = useNavigate();

  const { currentUser } = useContext(UserDataContext);
  const [routerValue] = useContext(RouterContext);
  const [{ agent }] = useContext(ManagerContext);

  const [isLoading, setIsLoading] = useState(false);

  const reload = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  const headerTitle = <div className={agent ? styles["up-header--agent-mode"] : ""}>
    {agent
      ? (
        <>
          <div>Day of</div>
          <div>{agent.fullName}</div>
        </>
      )
      : "Your Day"
    }
  </div>;

  return (
    <>
      <Header
        title={headerTitle}
        hideBack={true}
        toggleBack={() => {}}
        toggleReload={reload}
      />
      <div className={styles["up-container"]}>
        {!agent ? (
          !currentUser!.roles.includes(Roles.agent) ? (
            <AgentProfileNotExist />
          ) : !currentUser!.agentProfiles ? (
            <div className={styles["up-warning"]}>
              Please complete your Agent profile Address field to enable routing
              functionality.
              <Button
                onClick={() => navigate(`/profile`)}
                classes={{ root: styles["up-warning-button"] }}
              >
                Go to profile
              </Button>
            </div>
          ) : !routerValue.myLocation ? (
            <>
              Please enable Location service on your device to start using
              routing.
            </>
          ) : (
            <>
              {currentUser!.routerSettings.testMode.isTest ? (
                <div className={styles["up-warning-test"]}>
                  You're in test mode
                </div>
              ) : null}
              <LoadingSpinner isLoading={isLoading}>
                <DayScheduleCalendar />
              </LoadingSpinner>
            </>
          )) : (
          <LoadingSpinner isLoading={isLoading}>
            <DayScheduleCalendar />
          </LoadingSpinner>
        )}
      </div>
    </>
  );
}

export default DayScheduleView;
