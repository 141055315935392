import NotesRouter from "components/Pages/Notes/NotesRouter";
import ProfileRouter from "components/Pages/Profile/ProfileRouter";
import { Route, Routes } from "react-router-dom";

import PageNotFound from "./PageNotFound/PageNotFound";
import CompaniesRouter from "./Pages/Companies/CompaniesRouter";
import ReportsRouter from "./Pages/Reports/ReportsRouter";
import ContactsRouter from "./Pages/Contacts/Contacts.router";
import DayScheduleView from "./Pages/DaySchedule/DayScheduleView/DayScheduleView";
import LocationsRouter from "./Pages/Locations/LocationsRouter";
import TasksRouter from "./Pages/Tasks/TasksRouter";
import VisitsRouter from "./Pages/Visits/VisitsRouter";
import AgentsRouter from "./Pages/Agents/AgentsRouter";
import { useContext } from "react";
import { UserDataContext } from "../shared/Contexts";

function UpRouter() {
  const { currentUser } = useContext(UserDataContext);

  return (
    <Routes>
      <Route path={`*`} element={<PageNotFound />} />
      <Route path="/" element={<DayScheduleView />} />

      <Route path="notes/*" element={<NotesRouter />} />
      <Route path="profile/*" element={<ProfileRouter />} />
      <Route path="reports/*" element={<ReportsRouter />} />
      <Route path="locations/*" element={<LocationsRouter />} />
      <Route path="companies/*" element={<CompaniesRouter />} />
      <Route path="contacts/*" element={<ContactsRouter />} />
      <Route path="tasks/*" element={<TasksRouter />} />
      {currentUser?.roles.includes("manager") && (
        <Route path="agents/*" element={<AgentsRouter />} />
      )}
      <Route path="visits/*" element={<VisitsRouter />} />
    </Routes>
  );
}

export default UpRouter;
