import { Modal } from "@mui/material";

import Logo from "../../../assets/Splash-logo.svg";
import styles from "./LoadingSplash.module.scss";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

function LoadingSplash({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children?: JSX.Element | JSX.Element[];
}) {
  return (
    <>
      {isLoading ? (
        <>
          {process.env.REACT_APP_VOLT ? (
             <Modal open={isLoading} onClose={() => {}}>
              <div className={styles["spinner-container"]}>
              <LoadingSpinner isLoading={isLoading}>
                {children}
              </LoadingSpinner>
              </div>
            </Modal>
          ) : (
            <Modal open={isLoading} onClose={() => {}}>
              <div className={styles["loader-container"]}>
                <img src={Logo} alt="Navi Mate" />
              </div>
            </Modal>
          )}
        </>
      ) : (
        children
      )}
    </>
  );
}

export default LoadingSplash;
