import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./ViewReports.module.scss";
import Header from "components/Shared/Header/Header";

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function ViewReports() {
  const navigate = useNavigate();

  // use window.open to open the reports in a new tab 
  const openLink = (url: string) =>
    window.open(url, "_blank" );

  return <div className={styles["up-container"]}>
    <Header
      title={"Reports"}
      toggleBack={() => navigate("/")}
      hideReload={true}
      opacity={0}
    />
    <div className={styles["up-link-wrapper"]}>
      <Link
        onClick={() => openLink(`${baseUrl}/admin/reports/ups-reports/performance`)}
        target="_blank"
        underline="hover"
      >
        Performance
      </Link>
      <Link
        onClick={() => openLink(`${baseUrl}/admin/reports/ups-reports/locations`)}
        target="_blank"
        underline="hover"
      >
        Locations
      </Link>
      <Link
        onClick={() => openLink(`${baseUrl}/admin/reports/ups-reports/tasks`)}
        target="_blank"
        underline="hover"
      >
        Tasks
      </Link>
    </div>
    <div className={styles["up-info"]}>Best viewed on desktop</div>
  </div>;
}
