
import { useEffect, useState } from "react";

import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { getAgentsList } from "../../../../shared/Services/Agents.service";
import axios from "axios";
import { toast } from "react-toastify";
import { AgentI } from "@interfaces";
import AgentCard from "../AgentCard/AgentCard";

import styles from "./AgentsList.module.scss";

export default function AgentsList() {
  const [searchText, setSearchText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [originContent, setOriginContent] = useState<AgentI[]>([]);
  const [content, setContent] = useState<AgentI[]>([]);

  useEffect(() => {
    setIsLoading(true);
    getAgentsList().then(data => {
      if (axios.isAxiosError(data)) {
        toast.error(data.response?.data?.errors?.length ? data.response?.data?.errors[0]?.detail : "");
      } else {
        setOriginContent(data);
        setContent(data);
      }
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const regex = new RegExp(searchText);

    const updatedContent = originContent.filter(i => {
      return i.fullName.toLowerCase().match(regex) || i.email.toLowerCase().match(regex);
    });
    setContent(updatedContent);
  }, [searchText]);

  return <>
    <TextField
      hiddenLabel={true}
      fullWidth={true}
      onChange={(e) => setSearchText(e.target.value)}
      InputProps={{
        classes: {
          root: styles["up-search-root"],
          input: styles["up-search-input"],
        },
        endAdornment: (
          <InputAdornment
            position={"end"}
            classes={{ root: styles["up-search-icon"] }}
          >
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      variant={"filled"}
      placeholder={"Search"}
    />

    <LoadingSpinner isLoading={isLoading}>
      <div className={styles["up-agents-container"]}>
        <div className={styles["up-agents-wrapper"]}>
          {content.length > 0 ? (
            content.sort((a, b) => {
              const first = a.fullName.toLowerCase(),
                second = b.fullName.toLowerCase();

              if (first > second) return 1;
              else return -1;
            }).map((a, index) =>
              <AgentCard agent={a} key={a.uuid} />
            )
          ) : (
            <div className="up-no-items">No agents found</div>
          )}
        </div>
      </div>
    </LoadingSpinner>
  </>;
}
